<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_category()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Edycja kategorii</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <v-text-field
                  v-model="user_data.name"
                  label="Nazwa"
                  color="primary"
                ></v-text-field>

                <v-textarea
                  v-model="user_data.description"
                  label="Opis"
                  color="primary"
                ></v-textarea>

                <!-- <v-select
                  v-model="user_data.users"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  label="Dostęp do kategorii:"
                  single-line
                  chips
                  multiple
                ></v-select> -->

                <v-autocomplete
                  v-model="user_data.users"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  no-data-text="Brak wyników"
                  label="Dostęp do kategorii:"
                  :rules="$store.state.rules.not_null"
                  chips
                  multiple
                ></v-autocomplete>
               
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć kategorię?"
      @close="dialog = false;"
      @confirm="delete_category()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    category: 0,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy-lista',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy/kategoria',
      },
      {
        text: 'Edytuj kategorie',
        disabled: true,
        to: '/edit',
      }
    ],

    user_data: {
      category: 1,
      email: 'Rzeszów',
      name: 'Andrzej Maślak z ZOO',
      numebr: '1233',
      users: []
    },
    users: [],
  }),
  methods: {
    edit_category() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("name", this.user_data.name);
      formData.append("description", this.user_data.description);
      for(const index in this.user_data.users){
        formData.append("user["+index+"]", this.user_data.users[index]);
      }

      this.$axios({url: this.$store.state.api +'/faq_category/'+this.category+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/baza-wiedzy/kategoria/'+this.category);
          this.$store.commit("snackbar", {
            text: "Wprowadzono zmiany",
            color: "primary",
            btn_color: "white"
          });
          console.log(resp.data);
          // this.user_data = resp.data.user_assign;
          this.$store.commit('loader_off');
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    delete_category(){
      // #TODO nie dziala usuwanie, usunie ale kategoria dalej pozostaje i mozna ja edytowac oraz przegladac i nie mozna ponownie jej usunac
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/faq_category/'+this.category, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$router.push('/baza-wiedzy-lista');
          this.$store.commit("snackbar", {
            text: "Usunięto kategorie",
            color: "primary",
            btn_color: "white"
          });
          this.$store.commit('loader_off');
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    // #TODO Nie mozna wgrac wielu plikow (sypie 500)
    this.category = this.$route.params.category;
    console.log(this.category);

    // Edytowanie linku
    this.breadcrumb[2].to = '/baza-wiedzy/kategoria/'+this.category;

    this.$store.commit('loader');
    let loader = 2;
    this.$axios({url: this.$store.state.api +'/categoryShow/'+this.category, data: {}, method: 'GET' })
      .then(resp => {
        this.user_data = resp.data;
        // Wybieranie tylko id usera
        let users_id = [];
        for(const index in this.user_data.users){
          users_id.push(this.user_data.users[index].id);
        }
        this.user_data.users = users_id;
      })
      .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })

    this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        this.users = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>